<template>
  <div class="mainform">
    <div class="form formlabel">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="130px">
        <div class="col col4">
          <el-form-item label="序号" prop="sequenceNumber" >
            <el-input v-model="form.sequenceNumber" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="细项代码" prop="detailCode" >
            <el-input v-model="form.detailCode" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="细项描述" prop="detailName" >
            <el-input v-model="form.detailName" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="细项评分标准" prop="detailStandard" >
            <el-input v-model="form.detailStandard" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="评分方式" prop="strMode" >
            <el-select v-model="form.strMode" placeholder="">
              <el-option label="系统自动" value="系统自动"></el-option>
              <el-option label="手工评分" value="手工评分"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="用户接口" prop="userApi" >
            <el-input v-model="form.userApi" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="缺省分值" prop="defaultScore" >
            <el-input v-model="form.defaultScore" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="折算比率" prop="conversionRatio" >
            <el-input v-model="form.conversionRatio" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="合理分值从" prop="branchStart" >
            <el-input v-model="form.branchStart" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="合理分值到" prop="branchEnd" >
            <el-input v-model="form.branchEnd" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="指标展示明细页面" prop="detailsPage" >
            <el-input v-model="form.detailsPage" placeholder=""></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'

export default {
  name: 'IndicatorsAddDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {}
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
  .formlabel{
    :deep(.el-form-item__label){
      font-size: 14px !important;
    }
  }
}
</style>
